.main.article{

    article{
        @extend .mb-1;
        background:white;
        @extend .pt-5;
        @extend .px-4;
        h1{
            color:$lightgreen;
            font-size:rem-calc(24);
            margin-bottom:rem-calc(30);
        }

        h2{
            font-size:rem-calc(20);
        }
        ul{
            li::marker{
                color:$darkgreen;
            }
        }

    }

}
