.card--package{
    background-repeat:no-repeat;
    background-size:contain;
    background-color:#eaefec;

    position:relative;
    .card-header{
        background:linear-gradient(to top, #718783, #8aa09c);
        color:white;
        font-weight:bold;
        border-bottom:2px solid black;
    }
    .card-body{
        background:linear-gradient(to bottom,#bdcac6,#eaefec 30%);
    }
    h3{
        color:$darkgreen;
        font-size:rem-calc(16);
        font-weight:bold;
    }
    ul{

        padding-left:rem-calc(18);
        font-size:rem-calc(14);
        li{
            padding-bottom:5px;
            &::marker{
                color:$green;
            }
        }
    }


    .btn{
        position:absolute;
        bottom:10px;
        left:10px;
    }
}
.package__price{
    position:absolute;
    bottom:20px;
    right:0;

}

.package__imgholder{
    float:right;
    width:90px;
    height:120px;
}
