body{

    margin-top:rem-calc(5);

}



.btn-primary{
    background-image:linear-gradient(to bottom, #4f5856,#6f7b78);
    background-color:#6f7b78;
    border:1px solid #4a5956;
    border-radius:3px;
    color:white !important;
    transition:all 0.2s linear 0s;
    &:hover{
        color:white;
        background-image:none;
        background-color:#4f5856;
    }
}

a{
    transition:all 0.2s linear 0s;
}

.card-header{
    h2{
        font-family:"PT Sans Narrow", sans-serif;
        font-size:rem-calc(22) !important;
        font-weight:bold;
        margin:0;
    }

}

.col-form-label{
    text-align:right;
}

legend{
    font-size:1rem;
    font-weight:bold;
}

.accordion-button{
    font-weight:bold;
    color:black !important;
}

.grecaptcha-badge{
    z-index:1000;
}
