.header{

    color:white;


    > .row{
        background-image:linear-gradient(to bottom, #2c3e3d, #4a5d5a);
        background-origin:padding-box;
        margin-right:0;
    }
    a{
        color:$linkgreen;
        &:hover{
            color:$hovermenucolor;
        }
    }
    form{
        border-radius:0 5px 0 0;
        + .row{
            @extend .pb-1;
            border-radius:0 0 5px 5px;
            @extend .mb-1;
        }
    }
}

