.main{
    @extend .mt-1;
}
.aside{
    background:$grey;
    @extend .mb-1;
    display:block;
    border:0;
    .card-header{
        background:#101910;
        color:#94b500;
        padding:4px;
        .card-title{
            font-size:rem-calc(12);
            margin:0;
        }
    }
    .card-body{
        font-size:rem-calc(14);
    }
    a{
        color:black;
        text-decoration:none;
        &.link{
            color:$blue;
            &:hover{
                color:$green;
            }
        }
    }
}

.aside__content{
    font-size:0.75rem;
    ul{
        padding-left: 1.5em;
        li{
            &::marker{
                color:$green;
            }
        }
    }
    h3{
        color:$darkgreen;
        font-size:0.75rem;
        font-weight:bold;
    }
}

.aside--image-centered{

    img{
        padding:5px;
        margin:auto;
        display:block;
    }
}
