@font-face {
    font-family: 'PT Sans Narrow';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('../fonts/pt-sans-narrow-v12-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/pt-sans-narrow-v12-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* pt-sans-narrow-700 - latin */
@font-face {
    font-family: 'PT Sans Narrow';
    font-style: normal;
    font-weight: 700;
    src: local(''),
    url('../fonts/pt-sans-narrow-v12-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/pt-sans-narrow-v12-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
