.greenbox{
    padding:5px;

    background:linear-gradient(to bottom, #e3eeb4, #eaefeb);
    ul{
        margin:0;
    }
    li{
        &::marker{
            color:$green !important;
        }
    }
}
