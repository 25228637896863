.nav-main{

    margin-right:0;
    background:linear-gradient(to bottom, #718783, #8aa09c);
    border-bottom:1px solid white;
    align-items:center;

    .dropdown-menu{
        padding:0;
        li{
            border:1px solid black;
            border-top:none;
            a:hover{
                background-color:$hovermenucolor;

            }
        }
    }
    .nav-item{
      border-left:1px solid black;


        &:last-of-type {
            border-right:1px solid black;
        }
        > .nav-link{
            text-transform:uppercase;
            font-weight:bold;
            font-size:rem-calc(14);
        }
    }
    a{
        color:white;
        font-weight:bold;
    }
}

.bg-navgreen{
    background:linear-gradient(to bottom, #badf00, $hover);
    &.nav-item{
        border-left:1px solid white !important;
    }
    .dropdown-menu{
        li{
            border:1px solid white;

            a:hover{
                background-color:$linkgreen;

            }
        }
    }

}
