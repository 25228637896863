.domain-check{

    height:100%;
    .card-header{
        background:linear-gradient(to bottom, #accb36,#95b136);
        border-bottom:1px solid #778d28;
        color:$black;
        font-size:rem-calc(16);
        font-weight:bold;
    }

    h5{
        font-size:rem-calc(16);
        font-weight:bold;
    }
    ul{
        li{
            padding-bottom:5px;
            font-size:rem-calc(14);
            &::marker{
                color:$green;
            }
        }
    }

}

.domain-check__content{
    background:linear-gradient(to bottom, #e2eeb6,#eaefea 80%);
    padding:rem-calc(10);
}
