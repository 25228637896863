.footer{
    @extend .py-1;


    color:white;

    > .row{
        margin-right:0;
        background:$darkgreen;
        padding:5px 0;
        border-radius:0 0 10px 10px;
    }
    a{
        color:$linkgreen;
        &:hover{
            color:$hovermenucolor;
        }
    }
    ul{
        list-style:none;
        margin:0;
        li{
            display:inline;
            font-size:rem-calc(14);
            + li{
                &:before{
                    content:" | ";
                }
            }
        }
    }
}
